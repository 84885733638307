<template>
  <v-main>
    <v-fade-transition mode="out-in">
      <router-view 
        :admin="admin"
        @get-brand="getBrand"
      />
    </v-fade-transition>
  </v-main>
</template>

<script>
  export default {
    name: 'PageView',
    props: {
      admin: {
        type: Boolean,
        default: false,
      }
    },
    methods: {
      getBrand (id) {
        this.$emit('get-brand', id);
      }
    }
  }
</script>
